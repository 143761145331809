<script>
import DefaultMixin from "@/components/leiloes/mixin"

export default {
  name: 'ApresentacaoLotesSeletor',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.page.setActive('apresentacao-lotes')
    document.querySelector('.u-erp-layout-container').classList.add('leilao-telao-seletor')
  },
  destroyed() {
    document.querySelector('.u-erp-layout-container').classList.remove('leilao-telao-seletor')
  },
  methods: {},
  meta: {
    title: 'Apresentação dos Lotes',
    name: 'Apresentação dos Lotes'
  }
}
</script>

<template>
  <div class="seletor-apresentacao-telao">
    Escolha uma versão de layout de sua preferência:
    <div class="m-t-lg text-center">
      <div class="font-16">
        <u-icon name="sad-tear" type="fa" style="font-size: 32px" />
      </div>
      <strong>Nenhum template instalado</strong>. Fale com o administrador do sistema.
    </div>
  </div>
</template>

<style src="../apresentacao-telao/assets/seletor.styl" lang="stylus"/>
